import { DynamicsBcSettingsModuleProps } from '@src/pages/settings/types';
import React, { useEffect, useState } from 'react';

export const DraggableList: React.FC<DynamicsBcSettingsModuleProps> = ({ dynamicsBcUserDefaultData, setDynamicsBcUserDefaultData }) => {
  const initialPriorities = dynamicsBcUserDefaultData.customer_contact_priorities;

  const [items, setItems] = useState(initialPriorities || ['admin_contact_person', 'default_contact_person', 'first_contact_person']);
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);

  const onDragStart = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    setDraggingIndex(index);
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', items[index]);
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    e.preventDefault();
    if (draggingIndex === null || draggingIndex === index) return;

    const newItems = [...items];
    const draggedItem = newItems.splice(draggingIndex, 1)[0];
    newItems.splice(index, 0, draggedItem);
    setItems(newItems);
    setDraggingIndex(index);
  };

  const onDragEnd = () => {
    setDraggingIndex(null);
  };

  const handleCustomerContactPriorities = () => {
    if (!setDynamicsBcUserDefaultData) return;

    const currentPriorities = dynamicsBcUserDefaultData.customer_contact_priorities;
    const orderChanged = !currentPriorities || currentPriorities.some((priority, index) => priority !== items[index]);

    if (orderChanged) {
        setDynamicsBcUserDefaultData({
        ...dynamicsBcUserDefaultData,
        customer_contact_priorities: items,
      });
    }
  };

  useEffect(() => {
    handleCustomerContactPriorities();
  }, [items]);

  useEffect(() => {
    if (initialPriorities) {
      setItems(initialPriorities);
    }
  }, []);

  return (
    <div className="draggable-container">
      {items.map((item, index) => (
        <div
          className="draggable-item"
          key={item}
          draggable
          onDragStart={(e) => onDragStart(e, index)}
          onDragOver={(e) => onDragOver(e, index)}
          onDragEnd={onDragEnd}
        >
          {item.charAt(0).toUpperCase() + item.slice(1).replaceAll('_', ' ')}
        </div>
      ))}
    </div>
  );
};
