import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';

import type { DynamicsBcSettingsModuleProps } from '@src/pages/settings/types';
import type { DynamicsBcPaymentTerms } from './types';
import { allPaymentTerms } from '@src/Api';

export const DynamicsBcSelectPaymentTerms: React.FC<DynamicsBcSettingsModuleProps> = ({
  dynamicsBcUserDefaultData,
  setDynamicsBcUserDefaultData,
  handleSelectChange,
  currentMonthPaymentTermsError,
  setCurrentMonthPaymentTermsError,
}) => {
  const [paymentTerms, setPaymentTerms] = React.useState<DynamicsBcPaymentTerms[]>([]);

  React.useEffect(() => {
    const getPaymentTerms = async () => {
      try {
        const paymentTermsResponse = await allPaymentTerms();
        if (paymentTermsResponse && Array.isArray(paymentTermsResponse.data)) {
          setPaymentTerms(paymentTermsResponse.data);
        } else {
          setPaymentTerms([]);
        }
      } catch (error) {
        console.error('Failed to fetch payment terms:', error);
        setPaymentTerms([]);
      }
    };
  
    getPaymentTerms();
  }, []);

  const handleAutoPaymentTermsFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isAutoPaymentTermsEnabled = event.target.checked;
    if (setDynamicsBcUserDefaultData) {
      setDynamicsBcUserDefaultData({
        ...dynamicsBcUserDefaultData,
        autoPaymentTerms: event.target.checked,
      });

      if (isAutoPaymentTermsEnabled === false) {
        setDynamicsBcUserDefaultData({
          ...dynamicsBcUserDefaultData,
          autoPaymentTerms: event.target.checked,
          current_month_payment_terms: false,
        });
      }
    }
  };

  const handlePaymentTermCustomFieldName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setCurrentMonthPaymentTermsError) {
      setCurrentMonthPaymentTermsError(null);
    }

    if (setDynamicsBcUserDefaultData) {
      setDynamicsBcUserDefaultData({
        ...dynamicsBcUserDefaultData,
        current_month_payment_terms_custom_field_name: event.target.value,
      });
    }
  };

  const handleCurrentMonthPayments = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setDynamicsBcUserDefaultData) {
      setDynamicsBcUserDefaultData({
        ...dynamicsBcUserDefaultData,
        current_month_payment_terms: event.target.checked,
      });
    }
  };



  const showPaymentTermsSelectbox = (): boolean => {
    return !dynamicsBcUserDefaultData.autoPaymentTerms;
  };
  

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalAutoPaymentTerms">
        <Form.Label column sm={6}>
          Enable automatic calculate payment terms
        </Form.Label>
        <Col sm={6}>
          <Form.Check
            type="switch"
            id="custom-switch"
            label="Enable"
            checked={dynamicsBcUserDefaultData.autoPaymentTerms ? dynamicsBcUserDefaultData.autoPaymentTerms : false}
            onChange={handleAutoPaymentTermsFormChange}
          />
        </Col>
      </Form.Group>

      {dynamicsBcUserDefaultData.autoPaymentTerms === true && (
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={6}>
            Enable using payment terms for current month
          </Form.Label>
          <Col sm={6}>
            <Form.Check
              type="switch"
              id="custom-switch"
              label="Enable"
              checked={dynamicsBcUserDefaultData.current_month_payment_terms ? dynamicsBcUserDefaultData.current_month_payment_terms : false}
              onChange={handleCurrentMonthPayments}
            />
          </Col>
        </Form.Group>
      )}

      {dynamicsBcUserDefaultData.current_month_payment_terms === true && dynamicsBcUserDefaultData.autoPaymentTerms === true && (
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={6}>
            Specify name of payment term custom field
          </Form.Label>
          <Col sm={6}>
            <Form.Control
              type="text"
              aria-label="Floating Custom Field Control"
              value={
                dynamicsBcUserDefaultData.current_month_payment_terms_custom_field_name
                  ? dynamicsBcUserDefaultData.current_month_payment_terms_custom_field_name
                  : ''
              }
              onChange={handlePaymentTermCustomFieldName}
            />
            <div className="error-container-settings">
              <p className={currentMonthPaymentTermsError ? 'error-text' : ''}>{currentMonthPaymentTermsError}</p>
            </div>
          </Col>
        </Form.Group>
      )}

      {showPaymentTermsSelectbox() === true && (dynamicsBcUserDefaultData.current_month_payment_terms === false || !dynamicsBcUserDefaultData.current_month_payment_terms) && (
        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPaymentTerms">
          <Form.Label column sm={6}>
            Specify default payment terms
          </Form.Label>
          <Col sm={6}>
            <FloatingLabel controlId="floatingSelectPaymentTerms" label="Select payment terms">
              <Form.Select aria-label="Floating label select" value={dynamicsBcUserDefaultData.payment_terms?.paymentTermsId} onChange={handleSelectChange}>
                <option hidden value="" data-key={'payment_terms'}>
                  Open this select menu
                </option>
                {paymentTerms.length !== 0 &&
                  paymentTerms.map((paymentTerm) => (
                    <option
                      key={`paymentTerm-${paymentTerm.id}`}
                      value={paymentTerm.id}
                      data-key={'payment_terms'}
                      data-self={paymentTerm.displayName}
                      data-numberkey={'paymentTermsId'}
                      data-selfkey={'self'}
                    >
                      {paymentTerm.displayName}
                    </option>
                  ))}
              </Form.Select>
            </FloatingLabel>
          </Col>
        </Form.Group>
      )}
    </>
  );
};
