import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';

import type { DynamicsBcSettingsModuleProps } from '@src/pages/settings/types';

export const DynamicsBcThreeYearAgreement: React.FC<DynamicsBcSettingsModuleProps> = ({ dynamicsBcUserDefaultData, setDynamicsBcUserDefaultData }) => {

    const handleThreeYearAgreementField = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (setDynamicsBcUserDefaultData) {
            setDynamicsBcUserDefaultData({
                ...dynamicsBcUserDefaultData,
                three_year_agreement_field: event.target.value
            });
        }
    };

    return (
        <>
            <Form.Group as={Row} className="mb-3" controlId="formHorizontalThreeYearAgreementField">
                <Form.Label column sm={6}>
                    Specify custom field for three year agreements on project
                </Form.Label>
                <Col sm={6}>
                    <FloatingLabel controlId="floatingInputThreeYearAgreementField" label="Custom field name for agreement">
                        <Form.Control type="text" value={dynamicsBcUserDefaultData.three_year_agreement_field} onChange={handleThreeYearAgreementField} />
                    </FloatingLabel>
                </Col>
            </Form.Group>
        </>
    );
};
