import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';

import type { DynamicsBcSettingsModuleProps } from '@src/pages/settings/types';

export const DynamicsBcRoundingDifference: React.FC<DynamicsBcSettingsModuleProps> = ({ dynamicsBcUserDefaultData, setDynamicsBcUserDefaultData }) => {
    const handleRoundingDifferenceLineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (setDynamicsBcUserDefaultData) {
            setDynamicsBcUserDefaultData({
                ...dynamicsBcUserDefaultData,
                difference_line: event.target.value,
            });
        }
    };

    const handleRoundingDifferenceProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (setDynamicsBcUserDefaultData) {
            setDynamicsBcUserDefaultData({
                ...dynamicsBcUserDefaultData,
                difference_item_number: event.target.value
            });
        }
    };

    return (
        <>
            <Form.Group as={Row} className="mb-3" controlId="formHorizontalRoundingDifference">
                <Form.Label column sm={6}>
                    Specify line text for rounding difference
                </Form.Label>
                <Col sm={6}>
                    <FloatingLabel controlId="floatingInputRoundingDifference" label="Rounding difference">
                        <Form.Control type="text" value={dynamicsBcUserDefaultData.difference_line} onChange={handleRoundingDifferenceLineChange} />
                    </FloatingLabel>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formHorizontalRoundingDifferenceItemNumber">
                <Form.Label column sm={6}>
                    Specify item number for rounding difference
                </Form.Label>
                <Col sm={6}>
                    <FloatingLabel controlId="floatingInputRoundingDifferenceItemNumber" label="Product number for rounding difference">
                        <Form.Control type="text" value={dynamicsBcUserDefaultData.difference_item_number} onChange={handleRoundingDifferenceProductChange} />
                    </FloatingLabel>
                </Col>
            </Form.Group>
        </>
    );
};
