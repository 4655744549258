import React from 'react';
import { DynamicsBcSelectPaymentTerms } from './payment_terms';
import { DynamicsBcUploadEmail } from './upload_email';
import type { DynamicsBcSettingsModuleProps } from '@src/pages/settings/types';
import { DynamicsBcDefaultShipmentMethod } from './default_shipment_method';
import { DynamicsBcRoundingDifference } from './rounding_difference';
import { DynamicsBcThreeYearAgreement } from './three_year_agreement';
import DynamicsBcSelectTaxArea from './tax_area';

export const InvoiceForm: React.FC<DynamicsBcSettingsModuleProps> = ({
  dynamicsBcUserDefaultData,
  setDynamicsBcUserDefaultData,
  handleSelectChange,
  currentMonthPaymentTermsError,
  setCurrentMonthPaymentTermsError,
}) => {
  return (
    <>

      <DynamicsBcSelectPaymentTerms
        dynamicsBcUserDefaultData={dynamicsBcUserDefaultData}
        setDynamicsBcUserDefaultData={setDynamicsBcUserDefaultData}
        handleSelectChange={handleSelectChange}
        currentMonthPaymentTermsError={currentMonthPaymentTermsError}
        setCurrentMonthPaymentTermsError={setCurrentMonthPaymentTermsError}
      />

      <DynamicsBcDefaultShipmentMethod dynamicsBcUserDefaultData={dynamicsBcUserDefaultData} setDynamicsBcUserDefaultData={setDynamicsBcUserDefaultData} />

      <DynamicsBcSelectTaxArea
        dynamicsBcUserDefaultData={dynamicsBcUserDefaultData}
        setDynamicsBcUserDefaultData={setDynamicsBcUserDefaultData}
      />

      <DynamicsBcUploadEmail dynamicsBcUserDefaultData={dynamicsBcUserDefaultData} setDynamicsBcUserDefaultData={setDynamicsBcUserDefaultData} />
      <DynamicsBcRoundingDifference dynamicsBcUserDefaultData={dynamicsBcUserDefaultData} setDynamicsBcUserDefaultData={setDynamicsBcUserDefaultData} />
      <DynamicsBcThreeYearAgreement dynamicsBcUserDefaultData={dynamicsBcUserDefaultData} setDynamicsBcUserDefaultData={setDynamicsBcUserDefaultData} />
    </>
  );
};
