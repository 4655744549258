import React from 'react';
import { Row, Col, Card, Form, Spinner, Button } from 'react-bootstrap';
import { DynamicsForm } from './dynamics_BC';
import { RentmanForm } from './rentman';
import { MailgunForm } from './mailgun';
import { integrationByType, testConnection, updateUserIntegration } from '@src/Api';
import { CustomToast } from '@src/components/toast';

import rentmanDynamicsBcLogo from '@public/img/rentman-dynamicsbc-logo.png';
import rentmanLogo from '@public/img/rentman-logo.jpg';

import type { DynamicsBcUserSettings, RentmanUserSettings } from './types';
import { Container } from 'react-bootstrap';
import { AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

export const SettingsPage: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [dynamicsBcUserDefaultData, setDynamicsBcUserDefaultData] = React.useState<DynamicsBcUserSettings>({});
  const [rentmanUserDefaultData, setRentmanUserDefaultData] = React.useState<RentmanUserSettings>({});
  const [finalizeSyncStringError, setFinalizeSyncStringError] = React.useState<string | null>(null);
  const [invoiceTypeError, setInvoiceTypeError] = React.useState<string | null>(null);
  const [stockLocationSyncError, setStockLocationSyncError] = React.useState<string | null>(null);
  const [currentMonthPaymentTermsError, setCurrentMonthPaymentTermsError] = React.useState<string | null>(null);
  const [uploadPdfError, setUploadPdfError] = React.useState<string | null>(null);
  const [showToast, setShowToast] = React.useState<boolean>(false);

  React.useEffect(() => {
    const checkConnection = async () => {
      setLoading(true);
      const rentmanTest = await testConnection('rentman');

      if (rentmanTest?.status !== 200) {
        navigate('/connection-config', { state: { error: 'One of your Rentman keys are no longer valid. Please enter a valid key.' } });
      } else {
        setLoading(false);
      }
    };

    const getDefaultData = async () => {
      const integrationData = await integrationByType('dynamicsBc', 'data');
      if (integrationData) {
        setDynamicsBcUserDefaultData(integrationData.data.users_integrations_default_data);
      }

      const rentmanIntegrationData = await integrationByType('rentman', 'data');
      if (rentmanIntegrationData) {
        setRentmanUserDefaultData(rentmanIntegrationData.data.users_integrations_default_data);
      }
    };
    checkConnection();
    getDefaultData();
  }, []);

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    let hasErrors = false;

    setLoading(true);


    if (rentmanUserDefaultData.invoice_event_type === undefined || rentmanUserDefaultData.invoice_event_type.trim() === '') {
      setInvoiceTypeError('*Please select when to transfer invoices');
      hasErrors = true;
    }


    if (rentmanUserDefaultData.upload_pdf === true && rentmanUserDefaultData.upload_pdf_type === undefined) {
      setUploadPdfError('*Please select pdf type');
      hasErrors = true;
    }

    if (
      dynamicsBcUserDefaultData.autoPaymentTerms === true &&
      dynamicsBcUserDefaultData.current_month_payment_terms === true &&
      dynamicsBcUserDefaultData.current_month_payment_terms_custom_field_name === ''
    ) {
      setCurrentMonthPaymentTermsError('*Please specify a value for custom field name');
      hasErrors = true;
    }

    if (hasErrors) {
      setLoading(false);
      return;
    }

    const dynamicsBcResponse = await updateUserIntegration('dynamicsBc', dynamicsBcUserDefaultData);
    if (dynamicsBcResponse) {
      setDynamicsBcUserDefaultData(dynamicsBcResponse.data.users_integrations_default_data);
    }

    const rentmanResponse = await updateUserIntegration('rentman', rentmanUserDefaultData);
    if (rentmanResponse) {
      setRentmanUserDefaultData(rentmanResponse.data.users_integrations_default_data);
    }

    setShowToast(true);
    setLoading(false);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
    const option = event.target.options[event.target.selectedIndex];
    const dataObject = option.dataset;

    const selectKey = dataObject['key'];
    const selectNumberKey = dataObject['numberkey'];
    const selectSelfKey = dataObject['selfkey'];

    if ((selectKey !== undefined && !selectNumberKey) || !selectSelfKey) {
      delete dynamicsBcUserDefaultData[selectKey as string];

      setDynamicsBcUserDefaultData({
        ...dynamicsBcUserDefaultData,
      });
    }

    if (!selectKey || !selectNumberKey || !selectSelfKey) return;

    setDynamicsBcUserDefaultData({
      ...dynamicsBcUserDefaultData,
      [selectKey]: {
        [selectNumberKey]: `${option.value}`,
        [selectSelfKey]: `${dataObject.self}`,
      },
    });
  };

  return (
    <>
      <Container className="toast-b-right">
        <AnimatePresence>
          <CustomToast
            duration={3000}
            show={showToast}
            title="Success"
            message="Your settings were successfully changed"
            type="success"
            onClose={() => setShowToast(false)}
          />
        </AnimatePresence>
      </Container>
      <Row className="mt-4">
        <Col sm={12}>
          <Card>
            <Card.Header>Settings</Card.Header>
            <Card.Body>
              {loading ? (
                <Container className="data-loading-spinner-container">
                  <Spinner className="data-loading-spinner"></Spinner>
                </Container>
              ) : (
                <Form>
                  <Col className="sticky-header">
                    <Row className="mt-4 mb-4">
                      <Col md={11}>
                        <img src={rentmanDynamicsBcLogo} alt="Rentman and DynamicsBc logo" width="300" loading="lazy" />
                      </Col>
                      <Col md={1}>
                        <Button variant="primary" type="submit" disabled={loading} onClick={handleSubmit}>
                          {loading ? (
                            <>
                              <Spinner animation="border" size="sm" className="mr-2" /> ...
                            </>
                          ) : (
                            'Save'
                          )}
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>Here you can edit your settings for the Rentman - Dynamics Business Central integration.</p>
                      </Col>
                    </Row>
                    <hr />
                  </Col>
                  <Row className="mt-4 mb-4">
                    <Col>
                      <div className="settins-heading">
                        <img src={rentmanLogo} alt="Rentman logo" width="60" loading="lazy" />
                        <h2>Rentman settings</h2>
                      </div>
                      <h4>Rentman</h4>
                      <RentmanForm
                        setInvoiceTypeError={setInvoiceTypeError}
                        invoiceTypeError={invoiceTypeError}
                        rentmanUserDefaultData={rentmanUserDefaultData}
                        setRentmanUserDefaultData={setRentmanUserDefaultData}
                        finalizeSyncStringError={finalizeSyncStringError}
                        setFinalizeSyncStringError={setFinalizeSyncStringError}
                        stockLocationSyncError={stockLocationSyncError}
                        setStockLocationSyncError={setStockLocationSyncError}
                        uploadPdfError={uploadPdfError}
                        setUploadPdfError={setUploadPdfError}
                      />
                    </Col>
                  </Row>

                  <hr />

                  <DynamicsForm
                    dynamicsBcUserDefaultData={dynamicsBcUserDefaultData}
                    setDynamicsBcUserDefaultData={setDynamicsBcUserDefaultData}
                    handleSelectChange={handleSelectChange}
                    currentMonthPaymentTermsError={currentMonthPaymentTermsError}
                    setCurrentMonthPaymentTermsError={setCurrentMonthPaymentTermsError}
                  />

                  <hr />

                  <MailgunForm rentmanUserDefaultData={rentmanUserDefaultData} setRentmanUserDefaultData={setRentmanUserDefaultData} />

                  <hr />
                  <Row className="mt-4 mb-4">
                    <Col sm={11}></Col>
                    <Col sm={1}>
                      <Button variant="primary" type="submit" disabled={loading} onClick={handleSubmit}>
                        {loading ? (
                          <>
                            <Spinner animation="border" size="sm" className="mr-2" /> ...
                          </>
                        ) : (
                          'Save'
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
