import { login } from '@src/Api';
import { Button, Row, Col, Form, Spinner } from 'react-bootstrap';
import { useUsersContext } from '@src/contexts/users';
import React, { useState } from 'react';
import { motion } from 'framer-motion';

import { UsersActions } from '@src/contexts/users/types';

export const Login: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { usersDispatch } = useUsersContext();
  const [errorText, setErrorText] = useState<string>('');
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [isPasswordEmpty, setIsPasswordEmpty] = useState<boolean>(true);
  const [emailTouched, setEmailTouched] = useState<boolean>(false);
  const [passwordTouched, setPasswordTouched] = useState<boolean>(false);

  const validateEmail = (email: string) => {
    const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    setIsValidEmail(pattern.test(email));
  };

  const handleLogin = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();

    validateEmail(email);

    if (!isValidEmail || isPasswordEmpty) {
      setErrorText('Validation errors.');
      return;
    }

    if (email && password) {
      setLoading(true);
      const loginAction = await login(email, password);

      if (loginAction) {
        setLoading(false);
        if (loginAction.data.error) {
          setErrorText(loginAction.data.error);
          console.log(loginAction.data);
        } else {
          usersDispatch({ type: UsersActions.LOGIN, payload: loginAction.data.token });
        }
      }
    }
  };

  return (
    <motion.div initial={{ opacity: 0, x: -200 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }}>
      <Form>
        <Row>
          <Col sm={12}>
            <h2 className="mb-4">Login to your account</h2>
          </Col>
        </Row>
        <Row>
          <Form.Group className="mb-3" controlId="formHorizontalEmail">
            <Col>
              <Form.Control
                className={!isValidEmail && emailTouched ? 'error-field' : ''}
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  const value = e.target.value;
                  setEmail(value);
                }}
                onBlur={() => {
                  validateEmail(email);
                  setEmailTouched(true);
                }}
                onFocus={() => setEmailTouched(false)}
              />
            </Col>
            <div className="error-container-login error-text-align-left">
              {!isValidEmail && emailTouched && <p className="error-text">*Please enter a valid email.</p>}
            </div>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formHorizontalPassword">
            <Col>
              <Form.Control
                className={isPasswordEmpty && passwordTouched ? 'error-field' : ''}
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  const value = e.target.value;
                  setPassword(value);
                  if (value.trim() !== '') {
                    setIsPasswordEmpty(false);
                  }
                }}
                onBlur={() => {
                  if (password.trim() === '') {
                    setIsPasswordEmpty(true);
                  }
                  setPasswordTouched(true);
                }}
                onFocus={() => setPasswordTouched(false)}
              />
            </Col>
            <div className="error-container-login error-text-align-left">
              {isPasswordEmpty && passwordTouched && <p className="error-text">*Password cannot be empty.</p>}
            </div>
          </Form.Group>

          <motion.div initial={{ x: 0, opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.2, duration: 0.5 }}>
            <Form.Group className="mb-3 text-center">
              <Col>
                <Button type="submit" onClick={(e) => handleLogin(e)}>
                  Login
                </Button>
              </Col>
            </Form.Group>
          </motion.div>

          <Col className="custom-spinner text-center">{loading ? <Spinner animation="border"></Spinner> : <p className="error">{errorText}</p>}</Col>
        </Row>
      </Form>
    </motion.div>
  );
};
