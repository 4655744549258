import { ContentSection } from '@src/components/content-section';
import { TableOfContents } from '@src/components/table-of-contents';
import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

//Images
import apiKey from '@public/img/setup/rentman-api-key.png';
import webhook from '@public/img/setup/rentman-guide.jpg';
import insertWebhook from '@public/img/setup/rentman-webhook-token.png';
import applicationClientIdInsert from '@public/img/setup/application-client-id-insert.png';
import goToEntraId from '@public/img/setup/1go-to-entra-ID.png';
import goToAppRegistrations from '@public/img/setup/2go-to-app-registrations.png';
import pressNewRegistration from '@public/img/setup/3press-new-registration.png';
import enterInformationRegisterApp from '@public/img/setup/4enter-information-register-app.png';
import pressRegisterAppButton from '@public/img/setup/5press-register-button.png';
import applicationDirectoryId from '@public/img/setup/6appilcation-directory-id.png';
import addClientSecret from '@public/img/setup/7add-client-secret.png';
import clientSecretFields from '@public/img/setup/8description-expires.png';
import clientSecretValue from '@public/img/setup/10get-client-secret-value.png';
import clientSecretInsert from '@public/img/setup/client-secret-insert.png';
import searchIcon from '@public/img/setup/11search-icon.png';
import microsoftEntra from '@public/img/setup/12microsoft-entra.png';
import newEntra from '@public/img/setup/13create-new-entra.png';
import enterApplicationAndTenant from '@public/img/setup/14enter-application-tenant-id.png';
import activateEntra from '@public/img/setup/15activate-entra.png';
import clickRettighed from '@public/img/setup/17click-rettigheds.png';
import findRettighed from '@public/img/setup/18find-rettigheds.png';
import selectCompany from '@public/img/setup/19select-virksomhed.png';
import giveConsent from '@public/img/setup/20give-consent.png';
import apiPermissions from '@public/img/setup/21api-permissions.png';
import addPermission from '@public/img/setup/22add-permission.png';
import bcPermission from '@public/img/setup/23select-bc-api.png';
import delegatedPermissions from '@public/img/setup/24delegated-permissions.png';
import permissionsInDelegated from '@public/img/setup/25permissions-in-delegated.png';
import applicationPermissions from '@public/img/setup/26application-permissions.png';
import permissionsInApplication from '@public/img/setup/27permissions-in-application.png';
import giveApiPermissions from '@public/img/setup/28-give-api-perms.png';
import selectWebServices from '@public/img/setup/29select-webservices.png';
import pressNewButton from '@public/img/setup/30new-button.png';
import selectPage from '@public/img/setup/31select-page.png';
import selectCustomerCard from '@public/img/setup/32select-customer-card.png';
import enterNamePublic from '@public/img/setup/33enter-name-public.png';
import selectSalesInvoice from '@public/img/setup/34select-sales-invoice.png';
import enterSalesInvoice from '@public/img/setup/35enter-salesinvocie-public.png';
import saveConnection from '@public/img/setup/36save-connection.png';
import selectIntegrationCompany from '@public/img/setup/37select-company.png';

export const SetupPage: React.FC = () => {
  const contents = [
    {
      title: '1. API key integration',
      anchor: 'api-integration',
      content: [
        'To integrate your API key from Rentman, navigate to ',
        { bold: true, text: 'Integration > Connections ' },
        'or ',
        { link: '../connection-config', text: 'press this link' },
        '\n You can find a guide on how to generate an ',
        { link: 'https://support.rentman.io/hc/en-us/articles/360013767839-The-Rentman-API', text: 'API key from rentman here.' },
        { imageSrc: apiKey },
      ],
    },
    {
      title: '2. Activate webhooks',
      anchor: 'activate-webhooks',
      content: ['To activate webhooks in Rentman, you have to contact the ', { link: 'https://support.rentman.io/hc/en-us', text: 'Rentman Support Center' }],
    },
    {
      title: '3. Adjust webhook settings',
      anchor: 'adjust-webhook-settings',
      content: [
        'Adjust webhook settings by copying this URL: ',
        { link: 'https://rentman-economic.ew.r.appspot.com/rentman/hook', text: 'https://rentman-economic.ew.r.appspot.com/rentman/hook ' },
        'and then pressing save.',
        { imageSrc: webhook },
      ],
    },
    {
      title: '4. Insert webhook token',
      anchor: 'insert-webhook-token',
      content: [
        'Copy your webhook token and insert it into the Rentman form field under ',
        { bold: true, text: 'Integration > Connections \n' },
        { imageSrc: insertWebhook },
      ],
    },
    {
      title: '5. Application (client) ID and Directory (tenant) ID',
      anchor: 'application-directory-id-setup',
      content: [
        '1. Navigate to your azure portal account at ',
        { link: 'portal.azure.com', text: 'portal.azure.com' },
        ', and press the',
        { bold: true, text: ' Microsoft Entra ID ' },
        'in the left-hand menu.',
        { imageSrc: goToEntraId },
        '2. Select App registrations in the left-hand menu',
        { imageSrc: goToAppRegistrations },
        '3. Press the ',
        { bold: true, text: 'New registration ' },
        'button.',
        { imageSrc: pressNewRegistration },
        '4. Enter the following values into the fields: ',
        { list: ['Name: Rentman - Business Central', 'Redirect URI: Single-page application (SPA) + https://businesscentral.dynamics.com/OAuthLanding.htm'] },
        { imageSrc: enterInformationRegisterApp },
        '5. Press the ',
        { bold: true, text: 'Register ' },
        'button',
        { imageSrc: pressRegisterAppButton },
        '6. After registering the app, you are presented with your ',
        { bold: true, text: 'Application (client) ID ' },
        'and ',
        { bold: true, text: 'Directory (tenant) ID' },
        { imageSrc: applicationDirectoryId },
        '7. Enter these values into the IT Stack integration under ',
        { bold: true, text: 'Integration > Connections' },
        { imageSrc: applicationClientIdInsert },
        { bold: true, text: 'NOTE: ' },
        'These values will not work yet, but that will be covered later in the guide.'
      ]
    },
    {
      title: '6. Client Secret',
      anchor: 'client-secret-setup',
      content: [
        '1. Head back to the azure portal, and press the ',
        { bold: true, text: 'Add a certificate or secret ' },
        'button. ',
        { imageSrc: addClientSecret },
        '2. Enter the following values in the fields: ',
        { list: ['Description: Rentman BC Client secret', 'Expires: 730 days'] },
        'And press the ',
        { bold: true, text: 'Add ' },
        'button.',
        { imageSrc: clientSecretFields },
        '3. After adding your secret, you will be presented with your client ',
        { bold: true, text: 'Value ' },
        'and ',
        { bold: true, text: 'Secret ID ' },
        'copy the ',
        { bold: true, text: 'Value' },
        { imageSrc: clientSecretValue },
        '4. Add this to the IT Stack integration under ',
        { bold: true, text: 'Integration > Connections' },
        { imageSrc: clientSecretInsert },
        { bold: true, text: 'NOTE: ' },
        'This value will not work yet, but that will be covered later in the guide.'
      ]
    },
    {
      title: '7. Business Central Permissions',
      anchor: 'business-central-permissions',
      content: [
        '1. Log in to your business central account on ',
        { link: 'https://businesscentral.dynamics.com/', text: 'https://businesscentral.dynamics.com/' },
        ' and press the search icon in the top-right corner.',
        { imageSrc: searchIcon },
        '2. Search for ',
        { bold: true, text: 'entra ' },
        'and select ',
        { bold: true, text: 'Microsoft Entra-programmer' },
        { imageSrc: microsoftEntra },
        '3. Press the ',
        { bold: true, text: 'Ny ' },
        'button',
        { imageSrc: newEntra },
        '4. Enter the following values into the fields, some of which were entered in the integration earlier: ',
        { list: ['Klient-id: {your-application-(client)-id}', 'App-id: {your-directory-(tenant)-id}', 'Beskrivelse: Dynamics BC Integration', 'Appnavn: Dynamics BC Integration'] },
        { imageSrc: enterApplicationAndTenant },
        '5. After entering the values above, open the ',
        { bold: true, text: 'Tilstand ' },
        'field and select ',
        { bold: true, text: 'Aktiveret' },
        { imageSrc: activateEntra },
        '6. Open the ',
        { bold: true, text: 'Brugerrettigedssæt ' },
        'tab, and select the first row in the ',
        { bold: true, text: 'Rettighedssæt ' },
        'column.',
        { imageSrc: clickRettighed },
        '7. Use the search field and search for ',
        { bold: true, text: 'D365 full access ' },
        'and select the rettighedssæt',
        { imageSrc: findRettighed },
        '8. Select the ',
        { bold: true, text: 'Virksomhed ' },
        'column, and select the company you wish to use',
        { imageSrc: selectCompany },
        '9. After selecting your company, you need to give permissions by pressing the ',
        { bold: true, text: 'Giv samtykke ' },
        'button.',
        { imageSrc: giveConsent },
      ]
    },
    {
      title: '8. Azure API Permissions',
      anchor: 'azure-api-permissions',
      content: [
        '1. Head back to your Azure Portal at ',
        { link: 'portal.azure.com', text: 'portal.azure.com' },
        ', and select ',
        { bold: true, text: 'API permissions ' },
        'in the left-hand menu',
        { imageSrc: apiPermissions },
        '2. Press the ',
        { bold: true, text: 'Add a permission ' },
        'button',
        { imageSrc: addPermission },
        '3. Select the ',
        { bold: true, text: 'Dynamics 365 Business Central ' },
        'API permissions.',
        { imageSrc: bcPermission },
        '4. Press the ',
        { bold: true, text: 'Delegated permissions ' },
        'button.',
        { imageSrc: delegatedPermissions },
        '5. Select the permissions as shown on the image, and press the ',
        { bold: true, text: 'Add permissions ' },
        'button.',
        { imageSrc: permissionsInDelegated },
        '6. Press the ',
        { bold: true, text: 'Application permissions ' },
        'button',
        { imageSrc: applicationPermissions },
        '7. Select the permissions as shown on the image, and press the ',
        { bold: true, text: 'Add permissions ' },
        'button',
        { imageSrc: permissionsInApplication },
        '8. Once you have configured the permissions, press the ',
        { bold: true, text: 'Grant admin consent ' },
        'button',
        { imageSrc: giveApiPermissions }
      ]
    },
    {
      title: '9. Setup OData endpoints',
      anchor: 'odata-endpoints',
      content: [
        '1. Head back to your business central account on ',
        { link: 'https://businesscentral.dynamics.com/', text: 'https://businesscentral.dynamics.com/' },
        ' and press the search icon in the top-right corner.',
        { imageSrc: searchIcon },
        '2. Search for ',
        { bold: true, text: 'web' },
        'and select ',
        { bold: true, text: 'Web Services' },
        { imageSrc: selectWebServices },
        '3. Press the ',
        { bold: true, text: 'New ' },
        'button',
        { imageSrc: pressNewButton },
        '4. In the first column, select ',
        { bold: true, text: 'Page' },
        { imageSrc: selectPage },
        '5. In the second column, select ',
        { bold: true, text: '21 Customer Card' },
        { imageSrc: selectCustomerCard },
        '6. In the fourth column, enter ',
        { bold: true, text: 'Customers ' },
        'and check the public checkbox',
        { imageSrc: enterNamePublic },
        '7. Repeat steps 1-4, and in the second column, select ',
        { bold: true, text: '43 Sales Invoice' },
        { imageSrc: selectSalesInvoice },
        '8. In the fourth column, enter ',
        { bold: true, text: 'SalesInvoice ' },
        'and check the public checkbox',
        { imageSrc: enterSalesInvoice },
      ]
    },
    {
      title: '10. Select company',
      anchor: 'select-company',
      content: [
        '1. After setting up all of the above, head to ',
        {bold: true, text: 'Integration > Connections '},
        'in the IT Stack integration, and press the save button',
        {imageSrc: saveConnection},
        '2. Select your company from the dropdown, and press save again',
        {imageSrc: selectIntegrationCompany},
        {bold: true, text: 'You are now able to use the integration!'}
      ]
    }
  ];

  return (
    <Card className="mt-4">
      <Card.Header>Setup guide</Card.Header>
      <Card.Body>
        <Row>
          <Col className="toc-col">
            <div className="toc-container">
              <TableOfContents sections={contents}></TableOfContents>
            </div>
          </Col>
          <Col lg={9}>
            {contents.map((content) => (
              <div className="content-section" key={content.anchor} id={content.anchor}>
                <ContentSection {...content} />
              </div>
            ))}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
