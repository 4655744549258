import { DynamicsBcSettingsModuleProps } from '@src/pages/settings/types';
import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';

export const CustomerPostingGroups: React.FC<DynamicsBcSettingsModuleProps> = ({ dynamicsBcUserDefaultData, setDynamicsBcUserDefaultData }) => {
  const handleDkCustomerPostingGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setDynamicsBcUserDefaultData) {
      setDynamicsBcUserDefaultData({
        ...dynamicsBcUserDefaultData,
        customer_posting_group: event.target.value,
      });
    }
  };

  const handleEuCustomerPostingGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setDynamicsBcUserDefaultData) {
      setDynamicsBcUserDefaultData({
        ...dynamicsBcUserDefaultData,
        customer_posting_group_eu: event.target.value,
      });
    }
  };

  const handleRestCustomerPostingGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setDynamicsBcUserDefaultData) {
      setDynamicsBcUserDefaultData({
        ...dynamicsBcUserDefaultData,
        customer_posting_group_rest: event.target.value,
      });
    }
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalTaxArea">
        <Form.Label column sm={6}>
          Specify which customer posting group you want to use for customers in Denmark
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId='floatingInputDkCustomerPostingGroup' label='DK Customer Posting Group'>
            <Form.Control type="text" value={dynamicsBcUserDefaultData.customer_posting_group} onChange={handleDkCustomerPostingGroupChange} />
          </FloatingLabel>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formHorizontalTaxArea">
        <Form.Label column sm={6}>
          Specify which customer posting group you want to use for customers in EU
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId='floatingInputDkCustomerPostingGroup' label='EU Customer Posting Group'>
            <Form.Control type="text" value={dynamicsBcUserDefaultData.customer_posting_group_eu} onChange={handleEuCustomerPostingGroupChange} />
          </FloatingLabel>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formHorizontalTaxArea">
        <Form.Label column sm={6}>
          Specify which customer posting group you want to use for customers out of EU
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId='floatingInputDkCustomerPostingGroup' label='Out of EU Customer Posting Group'>
            <Form.Control type="text" value={dynamicsBcUserDefaultData.customer_posting_group_rest} onChange={handleRestCustomerPostingGroupChange} />
          </FloatingLabel>
        </Col>
      </Form.Group>
    </>
  );
};
