import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import type { DynamicsBcTaxArea, SelectedTaxArea } from './types';
import type { DynamicsBcSettingsModuleProps } from '@src/pages/settings/types';
import { allTaxAreas } from '@src/Api';

export const DynamicsBcSelectTaxArea: React.FC<DynamicsBcSettingsModuleProps> = ({
    dynamicsBcUserDefaultData,
    setDynamicsBcUserDefaultData
}) => {
    const [taxAreas, setTaxAreas] = React.useState<DynamicsBcTaxArea[]>([]);

    React.useEffect(() => {
        const fetchTaxAreas = async () => {
            try {
                const taxAreaResponse = await allTaxAreas();
                if (taxAreaResponse && Array.isArray(taxAreaResponse.data)) {
                    setTaxAreas(taxAreaResponse.data);
                } else {
                    setTaxAreas([]);
                }
            } catch (error) {
                console.error('Failed to fetch tax areas:', error);
                setTaxAreas([]);
            }
        };

        fetchTaxAreas();
    }, []);

    const handleDkTaxAreaChange = (selectedOption: SelectedTaxArea | null) => {
        if (selectedOption && setDynamicsBcUserDefaultData) {
            setDynamicsBcUserDefaultData({
                ...dynamicsBcUserDefaultData,
                tax_area: {
                    taxAreaCode: selectedOption.taxAreaCode,
                    taxAreaId: selectedOption.taxAreaId
                },
            });
        }
    };

    const handleEuTaxAreaChange = (selectedOption: SelectedTaxArea | null) => {
        if (selectedOption && setDynamicsBcUserDefaultData) {
            setDynamicsBcUserDefaultData({
                ...dynamicsBcUserDefaultData,
                tax_area_eu: {
                    taxAreaCode: selectedOption.taxAreaCode,
                    taxAreaId: selectedOption.taxAreaId
                },
            });
        }
    };

    const handleRestTaxAreaChange = (selectedOption: SelectedTaxArea | null) => {
        if (selectedOption && setDynamicsBcUserDefaultData) {
            setDynamicsBcUserDefaultData({
                ...dynamicsBcUserDefaultData,
                tax_area_rest: {
                    taxAreaCode: selectedOption.taxAreaCode,
                    taxAreaId: selectedOption.taxAreaId
                },
            });
        }
    };

    const taxAreaOptions = taxAreas.map((taxArea) => ({
        taxAreaId: taxArea.id,
        taxAreaCode: taxArea.code,
        label: taxArea.displayName
    }));

    return (
        <>
            <Form.Group as={Row} className="mb-3" controlId="formHorizontalTaxArea">
                <Form.Label column sm={6}>
                    Specify which tax area you want to use for invoices in Denmark
                </Form.Label>
                <Col sm={6}>
                    <Select
                        className='custom-z-index-tax-area'
                        classNamePrefix="react-select"
                        aria-label="Floating label select"
                        value={taxAreaOptions.find((option) => option.taxAreaId === dynamicsBcUserDefaultData.tax_area?.taxAreaId)}
                        onChange={handleDkTaxAreaChange}
                        options={taxAreaOptions}
                    />
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formHorizontalTaxArea">
                <Form.Label column sm={6}>
                    Specify which tax area you want to use for invoices in EU
                </Form.Label>
                <Col sm={6}>
                    <Select
                        className='custom-z-index-tax-area-eu'
                        classNamePrefix="react-select"
                        aria-label="Floating label select"
                        value={taxAreaOptions.find((option) => option.taxAreaId === dynamicsBcUserDefaultData.tax_area_eu?.taxAreaId)}
                        onChange={handleEuTaxAreaChange}
                        options={taxAreaOptions}
                    />
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formHorizontalTaxArea">
                <Form.Label column sm={6}>
                    Specify which tax area you want to use for invoices out of EU
                </Form.Label>
                <Col sm={6}>
                    <Select
                        className='custom-z-index-tax-area-rest'
                        classNamePrefix="react-select"
                        aria-label="Floating label select"
                        value={taxAreaOptions.find((option) => option.taxAreaId === dynamicsBcUserDefaultData.tax_area_rest?.taxAreaId)}
                        onChange={handleRestTaxAreaChange}
                        options={taxAreaOptions}
                    />
                </Col>
            </Form.Group>
        </>
    );
};

export default DynamicsBcSelectTaxArea;
