import React, { useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import type { TableOfContentsProps } from './types';

export const TableOfContents: React.FC<TableOfContentsProps> = ({ sections }) => {
  const [activeSection, setActiveSection] = useState<string | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const centerOfScreen = window.innerHeight / 2;
      let closestSectionId = null;
      let smallestDistanceFromTop = Infinity;
      let closestDistanceToCenter = Infinity;

      sections.forEach((section) => {
        const element = document.getElementById(section.anchor);
        if (!element) return;

        const rect = element.getBoundingClientRect();

        const distanceToCenter = Math.abs(centerOfScreen - (rect.top + rect.bottom) / 2);

        if (distanceToCenter < closestDistanceToCenter || (distanceToCenter === closestDistanceToCenter && rect.top < smallestDistanceFromTop)) {
          closestDistanceToCenter = distanceToCenter;
          smallestDistanceFromTop = rect.top;
          closestSectionId = section.anchor;
        }
      });

      if (closestSectionId) {
        setActiveSection(closestSectionId);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sections]);

  return (
    <Navbar className="sticky-top flex-column toc-fixed-width">
      <Nav className="flex-column nav-margin">
        {sections.map((section) => (
          <Nav.Item key={section.anchor}>
            <Nav.Link className={`fs-6 ${section.anchor === activeSection ? 'anchor-selected' : ''}`} href={`#${section.anchor}`} key={section.anchor}>
              {section.title}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    </Navbar>
  );
};
