import React from 'react';

import { RentmanSyncState } from './syncState';
import { RentmanUploadInvoicePdf } from './uploadInvoicePdf';

import type { RentmanSettingModuleProps } from '@src/pages/settings/types';

export const RentmanForm: React.FC<RentmanSettingModuleProps> = ({
  rentmanUserDefaultData,
  setRentmanUserDefaultData,
  invoiceTypeError,
  setInvoiceTypeError,
  uploadPdfError,
  setUploadPdfError,
}) => {
  return (
    <>
      <RentmanSyncState
        rentmanUserDefaultData={rentmanUserDefaultData}
        setRentmanUserDefaultData={setRentmanUserDefaultData}
        invoiceTypeError={invoiceTypeError}
        setInvoiceTypeError={setInvoiceTypeError}
      />

      <RentmanUploadInvoicePdf
        rentmanUserDefaultData={rentmanUserDefaultData}
        setRentmanUserDefaultData={setRentmanUserDefaultData}
        uploadPdfError={uploadPdfError}
        setUploadPdfError={setUploadPdfError}
      />
    </>
  );
};
