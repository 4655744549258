import React, { useState, useEffect } from 'react';
// User context
import { useUsersContext } from '@src/contexts/users';
import { UsersActions } from '@src/contexts/users/types';
// Types
import type { UsersIntergrationType, ErrorObject } from './types';
import type { AxiosResponse } from 'axios';
// API
import { allCompanies, setAllUsersIntegration, testConnection } from '@src/Api';
// Styles
import { Row, Col, Container } from 'react-bootstrap';
// Components
import RentmanForm from './rentman';
import DynamicsBcForm from './dynamicsBc';
import { useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { CustomToast } from '@src/components/toast';
import type { DynamicsBcCompany } from '../settings/dynamics_BC/invoice/types';

const defaultConfig = {
  users_integrations_type: '',
  users_integrations_api_data: {
    apiKey: '',
    apiSecret: '',
    apiHookKey: '',
  },
  users_integrations_connected: '',
};

export const UserConfigPage: React.FC = () => {
  const location = useLocation();
  const state = location.state as { error: string } | undefined;
  const [showToast, setShowToast] = React.useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState<{ rentman: boolean; dynamicsBc: boolean }>({ rentman: false, dynamicsBc: false });
  const [validationError, setValidationError] = useState<{ [fieldId: string]: boolean }>({});
  const [companies, setCompanies] = useState<DynamicsBcCompany[] | undefined>(undefined);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const { usersDispatch } = useUsersContext();
  const [config, setConfig] = useState<UsersIntergrationType>({
    rentman: { ...defaultConfig },
    dynamicsBc: { ...defaultConfig },
  });
  const [statusText, setStatusText] = useState<{ [x: string]: string }>({
    rentman: '...',
    dynamicsBc: '...',
  });
  const [loading, setLoading] = useState<{ [x: string]: boolean }>({
    rentman: false,
    dynamicsBc: false,
  });
  const [errorObject, setErrorObject] = useState<ErrorObject>({
    rentman: null,
    dynamicsBc: null,
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleResponse = async (response: AxiosResponse | null, type: 'rentman' | 'dynamicsBc'): Promise<void> => {
    console.log('Response: ', response);
    if (response && typeof response.data === 'object') {
      if ('error' in response.data) {
        setStatusText({
          ...statusText,
          [type]: ` ${response.status}: ${response.data.status ? response.data.status : response.data.error}`,
        });
        setErrorObject({
          ...errorObject,
          [type]: response.data.error as object,
        });
      } else {
        setStatusText({
          [type]: ` ${response.status}: ${response.data.message}`,
        });
        setErrorObject({
          ...errorObject,
          [type]: null,
        });
        if (response && response.status >= 200 && response.status < 300) {
          setStatusText({
            [type]: ` ${response.status}: ${response.data.message}`
          })
          setSubmissionSuccess((prevState) => ({ ...prevState, [type]: true }));
        } else {
          setSubmissionSuccess((prevState) => ({ ...prevState, [type]: false }));
        }
      }

      if (response.data.integration) {
        setConfig((prevConfig) => ({
          ...prevConfig,
          [type]: response.data.integration,
        }));
      }

      if (response.data.token) {
        usersDispatch({ type: UsersActions.LOGIN, payload: response.data.token });
      }
    }
  };

  const handleRentmanValueChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setConfig({
      ...config,
      rentman: {
        ...config.rentman,
        users_integrations_api_data: {
          ...config.rentman.users_integrations_api_data,
          [event.target.id]: event.target.value,
        },
      },
    });
  };

  const handleDynamicsBcValueChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setConfig({
      ...config,
      dynamicsBc: {
        ...config.dynamicsBc,
        users_integrations_api_data: {
          ...config.dynamicsBc.users_integrations_api_data,
          [event.target.id]: event.target.value,
        },
      },
    });
  };

  const handleDynamicsBcCompanyChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCompanyId = event.target.value;
    if (companies !== undefined) {
      const selectedCompany = companies.find(company => company.id === selectedCompanyId);
      if (selectedCompany) {
        setSelectedCompany(selectedCompanyId);
        setSelectedCompanyName(selectedCompany.name);
      }
    }
  };


  const handleValidationError = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const fieldId = event.target.name;
    setValidationError({
      ...validationError,
      [fieldId]: value.trim() === '',
    });
  };

  const handleFormSubmit = async (e: React.MouseEvent<HTMLButtonElement>, type: 'rentman' | 'dynamicsBc'): Promise<void> => {
    e.preventDefault();

    setLoading({
      [type]: true,
    });

    const response = await setAllUsersIntegration(config, type);
    await handleResponse(response, type);

    setLoading({
      [type]: false,
    });

    setFormSubmitted(true);
  };

  //Function to fetch all companies from Dynamics BC for user
  const fetchCompanies = async () => {
    setLoading(prev => ({ ...prev, dynamicsBc: true }));
    try {
      const response = await allCompanies();
      if (response && response.data) {
        setCompanies(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch companies:', error);
    } finally {
      setLoading(prev => ({ ...prev, dynamicsBc: false }));
    }
  };

  //Use effect hook for setting the selected company in backend upon change of selected Company.
  useEffect(() => {
    if (!selectedCompany) return;

    const newConfig = {
      ...config,
      dynamicsBc: {
        ...config.dynamicsBc,
        users_integrations_api_data: {
          ...config.dynamicsBc.users_integrations_api_data,
          selectedCompany: selectedCompany,
          selectedCompanyName: selectedCompanyName
        },
      },
    };

    const updateIntegration = async () => {
      setLoading({ ...loading, dynamicsBc: true });
      const response = await setAllUsersIntegration(newConfig, 'dynamicsBc');
      await handleResponse(response, 'dynamicsBc');
      setLoading({ ...loading, dynamicsBc: false });
      setFormSubmitted(true);
    };

    updateIntegration();
  }, [selectedCompany]);

  //Use effect hook for fetching companies upon entering correct values
  useEffect(() => {
    if (submissionSuccess.dynamicsBc === true) {
      fetchCompanies()
    }
  }, [submissionSuccess.dynamicsBc])


  useEffect(() => {
    if (state && state.error) {
      setShowToast(true);
    }
  }, [state]);

  useEffect(() => {
    const getIntegrationHandler = async (): Promise<void> => {
      const testRentmanConnectionResponse = await testConnection('rentman');
      await handleResponse(testRentmanConnectionResponse, 'rentman');

      const testDynamicsBcConnectionResponse = await testConnection('dynamicsBc');
      await handleResponse(testDynamicsBcConnectionResponse, 'dynamicsBc');

      if (testDynamicsBcConnectionResponse && testDynamicsBcConnectionResponse.status === 200) {
        fetchCompanies();
      }
    };
    getIntegrationHandler();
  }, []);

  return (
    <>
      <Container className="toast-b-right">
        <AnimatePresence>
          <CustomToast
            duration={6000}
            show={showToast}
            title="Error"
            message={location?.state?.error || 'An unknown error occurred'}
            type="error"
            onClose={() => setShowToast(false)}
          />
        </AnimatePresence>
      </Container>
      <Row className="mt-4">
        <Col sm={6}>
          <RentmanForm
            config={config}
            loading={loading}
            statusText={statusText}
            errorObject={errorObject}
            formSubmitted={formSubmitted}
            validationError={validationError}
            handleValidationError={handleValidationError}
            handleRentmanValueChanged={handleRentmanValueChanged}
            handleFormSubmit={handleFormSubmit}
            submissionSuccess={submissionSuccess}
            setSubmissionSuccess={setSubmissionSuccess}
          />
        </Col>

        <Col sm={6}>
          <DynamicsBcForm
            config={config}
            loading={loading}
            statusText={statusText}
            errorObject={errorObject}
            formSubmitted={formSubmitted}
            validationError={validationError}
            handleValidationError={handleValidationError}
            handleDynamicsBcValueChanged={handleDynamicsBcValueChanged}
            handleDynamicsBcCompanyChanged={handleDynamicsBcCompanyChanged}
            handleFormSubmit={handleFormSubmit}
            submissionSuccess={submissionSuccess}
            setSubmissionSuccess={setSubmissionSuccess}
            companies={companies}
          />
        </Col>
      </Row>
    </>
  );
};
