import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { CustomerForm } from './customer';
import { InvoiceForm } from './invoice';

import dynamicsBcLogo from '@public/img/dynamicsbc-logo.png'

import type { DynamicsBcSettingsModuleProps } from '@src/pages/settings/types';
import { ProductForm } from './products';

export const DynamicsForm: React.FC<DynamicsBcSettingsModuleProps> = ({
  dynamicsBcUserDefaultData,
  setDynamicsBcUserDefaultData,
  handleSelectChange,
  currentMonthPaymentTermsError,
  setCurrentMonthPaymentTermsError,
}) => {
  return (
    <>
      <Row className="mt-4 mb-4">
        <Col>
          <div className="settins-heading">
            <img src={dynamicsBcLogo} alt="Dynamics BC logo" width="75" loading="lazy" />
            <h2>Dynamics Business Central Settings</h2>
          </div>
          <h4>Customer</h4>
          <p>Set how customers are created and updated in your accounts. Customer data is received with the order transfer.</p>
          <CustomerForm
            dynamicsBcUserDefaultData={dynamicsBcUserDefaultData}
            setDynamicsBcUserDefaultData={setDynamicsBcUserDefaultData}
            handleSelectChange={handleSelectChange}
          />
        </Col>
      </Row>

      <hr />

      <Row className="mt-4 mb-4">
        <Col>
          <h4>Invoice</h4>
          <p>Set how invoices should be created in your accounts.</p>
          <InvoiceForm
            dynamicsBcUserDefaultData={dynamicsBcUserDefaultData}
            setDynamicsBcUserDefaultData={setDynamicsBcUserDefaultData}
            handleSelectChange={handleSelectChange}
            currentMonthPaymentTermsError={currentMonthPaymentTermsError}
            setCurrentMonthPaymentTermsError={setCurrentMonthPaymentTermsError}
          />
        </Col>
      </Row>

      <hr />

      <Row>
        <Col className="mt-4 mb-4">
          <h4>Products</h4>
          <p>Set how products are created and updated in Dynamics Business Central.</p>
          <ProductForm
            dynamicsBcUserDefaultData={dynamicsBcUserDefaultData}
            setDynamicsBcUserDefaultData={setDynamicsBcUserDefaultData}
            handleSelectChange={handleSelectChange}
          />
        </Col>
      </Row>
    </>
  );
};
