import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import type { DynamicsBcProductSettings, SelectedUnitOfMeasure, UnitOfMeasure } from './types';
import { allUnitsOfMeasure } from '@src/Api';

export const CrewProductUnit: React.FC<DynamicsBcProductSettings> = ({ dynamicsBcUserDefaultData, setDynamicsBcUserDefaultData }) => {
    const [unitsOfMeasure, setUnitsOfMeasure] = React.useState<UnitOfMeasure[]>([]);

    React.useEffect(() => {
        const fetchUnitsOfMeasure = async () => {
            try {
                const unitsOfMeasureResponse = await allUnitsOfMeasure();
                if (unitsOfMeasureResponse && Array.isArray(unitsOfMeasureResponse.data)) {
                    setUnitsOfMeasure(unitsOfMeasureResponse.data);
                } else {
                    setUnitsOfMeasure([]);
                }
            } catch (error) {
                console.error('Failed to fetch units of measure:', error);
                setUnitsOfMeasure([]);
            }
        };

        fetchUnitsOfMeasure();
    }, []);

    const baseUnitOfMeasureChange = (selectedOption: SelectedUnitOfMeasure | null) => {
        if (selectedOption && setDynamicsBcUserDefaultData) {
            setDynamicsBcUserDefaultData({
                ...dynamicsBcUserDefaultData,
                crew_unit_of_measure: {
                    unitOfMeasureId: selectedOption.unitOfMeasureId,
                    unitOfMeasureCode: selectedOption.unitOfMeasureCode
                }
            });
        }
    };

    const crewUnitsOfMeasureOptions = unitsOfMeasure.map((unitOfMeasure) => ({
        unitOfMeasureId: unitOfMeasure.id,
        unitOfMeasureCode: unitOfMeasure.code,
        label: unitOfMeasure.displayName
    }));

    const selectedUnit = crewUnitsOfMeasureOptions.find(
        (option) => option.unitOfMeasureId === dynamicsBcUserDefaultData.crew_unit_of_measure?.unitOfMeasureId
    ) || null;

    return (
        <Form.Group as={Row} className="mb-3" controlId="formHorizontalProductUnit">
            <Form.Label column sm={6}>
                Specify unit for Crew
            </Form.Label>
            <Col sm={6} className="custom-z-index-unit">
                <Select
                    className='custom-z-index-crew'
                    classNamePrefix="react-select"
                    aria-label="Default base unit of measure select"
                    value={selectedUnit}
                    onChange={baseUnitOfMeasureChange}
                    options={crewUnitsOfMeasureOptions}
                />
            </Col>
        </Form.Group>
    );
};
