import { DynamicsBcSettingsModuleProps } from '@src/pages/settings/types';
import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { DraggableList } from './draggableList';
import { CustomerPostingGroups } from './customerPostingGroups';

export const CustomerContactPriorities: React.FC<DynamicsBcSettingsModuleProps> = ({ dynamicsBcUserDefaultData, setDynamicsBcUserDefaultData }) => {
  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalCustomerContactPriorities">
        <Form.Label column sm={6}>
          Drag items to prioritize which customer or company to use as customer contact, where the first item in the list has the highest priority
        </Form.Label>
        <Col sm={6}>
          <DraggableList dynamicsBcUserDefaultData={dynamicsBcUserDefaultData} setDynamicsBcUserDefaultData={setDynamicsBcUserDefaultData} />
        </Col>
      </Form.Group>
        <CustomerPostingGroups dynamicsBcUserDefaultData={dynamicsBcUserDefaultData} setDynamicsBcUserDefaultData={setDynamicsBcUserDefaultData}/>
    </>
  );
};
