import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Tabs, Tab } from 'react-bootstrap';
import { CustomToast } from '@src/components/toast';
// Types
import { InvoiceData } from './types';
// Components
import { PaginationComponent } from '@src/components/pagination';
// API
import { allInvoices } from '@src/Api';
import { Container } from 'react-bootstrap';
import { AnimatePresence } from 'framer-motion';
import { InvoiceTab } from './invoiceTab';

export const SyncOverviewPage: React.FC = () => {
  const [invoicesData, setInvoicesData] = useState<InvoiceData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalResults, setTotalResults] = useState<number>(25);
  const [resultsPerPage, setResultsPerPage] = useState<number>(25);
  const [toasts, setToasts] = useState<{ id: number; message: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState('invoices');

  const handleSelectTab = (key: string | null) => {
    if (key) {
      setActiveKey(key);
      setCurrentPage(0);
      setTotalResults(10);
      setInvoicesData([]);
    }
  };

  const fetchSyncData = async () => {
    setLoading(true);
    if (activeKey === 'invoices') {
      const response = await allInvoices(resultsPerPage, currentPage);

      if (response) {
        setInvoicesData(response.data.results);
        setTotalResults(response.data.total);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    const initializeData = async () => {
      setLoading(true);
      setActiveKey('invoices');

      fetchSyncData();
    };

    initializeData();
  }, [currentPage, resultsPerPage, activeKey]);

  const removeToast = (id: number) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  const handleDropdownChange = (value: number) => {
    setResultsPerPage(value);
  };

  return (
    <>
      <Container className="toast-b-right">
        <AnimatePresence>
          {toasts.map((toast, index) => (
            <CustomToast
              duration={3000}
              key={index}
              show={true}
              title={`Sync status for ID: ${toast.id}`}
              message={toast.message}
              type="success"
              onClose={() => removeToast(toast.id)}
            />
          ))}
        </AnimatePresence>
      </Container>
      <Row className="mt-4 sync-overview">
        <Col sm={12}>
          <Card>
            <Card.Header>Sync overview</Card.Header>
            <Card.Body>
              <Tabs className="custom-tab" activeKey={activeKey} id="sync-overview-tabs" onSelect={handleSelectTab}>
                <Tab eventKey="invoices" title="Invoices">
                  <InvoiceTab invoiceData={invoicesData} loading={loading} setToasts={setToasts} fetchSyncData={fetchSyncData} />
                </Tab>
              </Tabs>
            </Card.Body>
            <Row className="mt-4">
              <Col>
                <PaginationComponent
                  itemsCount={totalResults}
                  itemsPerPage={resultsPerPage}
                  currentPage={currentPage + 1}
                  setCurrentPage={(pageChangeTo) => setCurrentPage(pageChangeTo - 1)}
                />
              </Col>
              <Col className="result-dropdown">
                <select className="form-select dropdown" value={resultsPerPage} onChange={(e) => handleDropdownChange(parseInt(e.target.value))}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
