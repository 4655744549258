import React from 'react';
import type { DynamicsBcSettingsModuleProps } from '@src/pages/settings/types';
import { CustomerContactPriorities } from './customerContact';

export const CustomerForm: React.FC<DynamicsBcSettingsModuleProps> = ({ dynamicsBcUserDefaultData, setDynamicsBcUserDefaultData }) => {

  return (
    <>
      <CustomerContactPriorities dynamicsBcUserDefaultData={dynamicsBcUserDefaultData} setDynamicsBcUserDefaultData={setDynamicsBcUserDefaultData} />
    </>
  );
};
