import React from 'react';
import { Accordion, Modal, Button } from 'react-bootstrap';
import { syntaxHighlight } from '@src/components/syntax-highlight/index';
import type { FailureDetail, LogsData } from './types';
import FoldableFailure from './foldableFailure';

interface ModalComponentProps {
  showModal: boolean;
  handleModal: () => void;
  selectedLog: LogsData | null;
}

const ModalComponent: React.FC<ModalComponentProps> = ({ showModal, handleModal, selectedLog }) => {
  const logContent = selectedLog
    ? `
Log ID: ${selectedLog.logs_id}
Invoice ID: ${selectedLog.logs_invoice_id}
Type: ${selectedLog.logs_type}
Event: ${selectedLog.logs_message}
Status: ${selectedLog.logs_status}
Timestamp: ${new Date(selectedLog.logs_created).toLocaleString('da-DK')}
    `
    : '';

  const highlightedResponse = selectedLog ? syntaxHighlight(JSON.stringify(selectedLog.logs_response, undefined, 4)) : '';

  const failureResponse = selectedLog?.logs_response as { failures: FailureDetail[] };
  const failures = failureResponse?.failures ?? [];

  return (
    <Modal size="lg" show={showModal} onHide={handleModal} className="modal-data" aria-labelledby="contained-modal-title-vcenter" centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion defaultActiveKey={['0']} alwaysOpen flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Response</Accordion.Header>
            <Accordion.Body>
              {selectedLog && (
                <div>
                  <pre>{logContent}</pre>
                  <p>Log response:</p>
                  {failures.length > 0 ? (
                    failures.map((failure, index) => <FoldableFailure key={index} failure={{ ...failure, isOpen: false }} syntaxHighlight={syntaxHighlight} />)
                  ) : (
                    <pre dangerouslySetInnerHTML={{ __html: highlightedResponse }} />
                  )}
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalComponent;
