import React from 'react';
// Styles
import { Card, Form, Button, Spinner, FloatingLabel } from 'react-bootstrap';
import { syntaxHighlight } from '@src/components/syntax-highlight';
import SyntaxHighlighter from 'react-syntax-highlighter';
import atomOneLight from 'react-syntax-highlighter/dist/esm/styles/hljs/atom-one-light';
// Types
import type { UsersIntergrationFormProps } from './types';
// Components
import CopyToClipboardButton from '@src/components/copy-clipboard';

import type { DynamicsBcUsersApiData } from './types';

const DynamicsBcForm: React.FC<UsersIntergrationFormProps> = ({
  config,
  loading,
  statusText,
  errorObject,
  formSubmitted,
  validationError,
  handleValidationError,
  handleDynamicsBcValueChanged,
  handleDynamicsBcCompanyChanged,
  handleFormSubmit,
  submissionSuccess,
  setSubmissionSuccess,
  companies
}) => {
  const dynamicsBcApi = config.dynamicsBc.users_integrations_api_data as DynamicsBcUsersApiData;

  return (
    <>
      <Card>
        <Card.Header>Dynamics business central connection</Card.Header>
        <Card.Body>
          <Form>
            <Form.Group className="mb-3 connection-field" controlId="clientId">
              <Form.Label>Application (client) ID</Form.Label>
              <Form.Control
                name="clientId"
                type="text"
                placeholder="Enter Dynamics Application (client) ID"
                value={submissionSuccess.dynamicsBc ? '****************' : dynamicsBcApi.clientId}
                onChange={handleDynamicsBcValueChanged}
                onBlur={handleValidationError}
                onFocus={() => setSubmissionSuccess((prevState) => ({ ...prevState, dynamicsBc: false }))}
                className={!validationError['clientId'] ? '' : 'error-field'}
              />
              <p className="error-text">{!validationError['clientId'] ? '' : '* You need to enter your Application (client) ID'}</p>
            </Form.Group>
            <Form.Group className="mb-3 connection-field" controlId="tenantId">
              <Form.Label>Directory (tenant) ID</Form.Label>
              <Form.Control
                name="tenantId"
                type="text"
                placeholder="Enter Dynamics Directory (tenant) ID"
                value={submissionSuccess.dynamicsBc ? '****************' : dynamicsBcApi.tenantId}
                onChange={handleDynamicsBcValueChanged}
                onBlur={handleValidationError}
                onFocus={() => setSubmissionSuccess((prevState) => ({ ...prevState, dynamicsBc: false }))}
                className={!validationError['tenantId'] ? '' : 'error-field'}
              />
              <p className="error-text">{!validationError['tenantId'] ? '' : '* You need to enter your Directory (tenant) ID'}</p>
            </Form.Group>
            <Form.Group className="mb-3 connection-field" controlId="clientSecret">
              <Form.Label>Client Secret</Form.Label>
              <Form.Control
                name="clientSecret"
                type="text"
                placeholder="Enter Dynamics Client Secret"
                value={submissionSuccess.dynamicsBc ? '****************' : dynamicsBcApi.clientSecret}
                onChange={handleDynamicsBcValueChanged}
                onBlur={handleValidationError}
                onFocus={() => setSubmissionSuccess((prevState) => ({ ...prevState, dynamicsBc: false }))}
                className={!validationError['clientSecret'] ? '' : 'error-field'}
              />
              <p className="error-text">{!validationError['clientSecret'] ? '' : '* You need to enter your Client Secret'}</p>
            </Form.Group>
            <Button className='mb-3' variant="primary" type="submit" disabled={loading['dynamicsBc'] === true ? true : false} onClick={(e) => handleFormSubmit(e, 'dynamicsBc')}>
              Save
            </Button>
            <Form.Group className='mb-4'>
              <FloatingLabel controlId="floatingSelectRentmanInvoiceStatus" label="Select company after saving integration details">
                <Form.Select
                  name="selectedCompany"
                  as="select"
                  id='selectedCompany'
                  value={(config.dynamicsBc.users_integrations_api_data as DynamicsBcUsersApiData).selectedCompany || ''}
                  onChange={handleDynamicsBcCompanyChanged}
                  disabled={!submissionSuccess.dynamicsBc ? true : false}>
                  <option value="" hidden>Open this select menu</option>
                  {companies?.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
      <Card className="mt-4">
        <Card.Header>Debug</Card.Header>
        <Card.Body>
          {loading['dynamicsBc'] && (
            <>
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </>
          )}
          {formSubmitted && (
            <>
              Connection status:
              <pre dangerouslySetInnerHTML={{ __html: syntaxHighlight(JSON.stringify(statusText['dynamicsBc'], undefined, 4)) }} />
              {errorObject.rentman && (
                <>
                  <div>
                    <SyntaxHighlighter language="json" style={atomOneLight}>
                      {JSON.stringify(errorObject.rentman, null, 2)}
                    </SyntaxHighlighter>
                  </div>
                  <CopyToClipboardButton textToCopy={JSON.stringify(errorObject.rentman, null, 2)} buttonText="Copy" successText="Copied!" />
                </>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default DynamicsBcForm;
