import React, { useEffect } from 'react';
import { Pagination } from 'react-bootstrap';
import type { PaginationInterface } from './types';

export const PaginationComponent: React.FC<PaginationInterface> = ({ itemsCount, itemsPerPage, currentPage, setCurrentPage }) => {
  const pagesCount: number = Math.ceil(itemsCount / itemsPerPage);
  const isCurrentPageFirst: boolean = currentPage === 1;
  const isCurrentPageLast: boolean = currentPage === pagesCount;

  const changePage = (pageNumber: number) => {
    if (currentPage === pageNumber) return;
    setCurrentPage(pageNumber);
  };

  const onPageNumberClick = (pageNumber: number) => {
    changePage(pageNumber);
  };

  const onPreviousPageClick = () => {
    changePage(currentPage - 1);
  };

  const onNextPageClick = () => {
    changePage(currentPage + 1);
  };

  const setLastPageAsCurrent = () => {
    if (itemsCount === 0) return;

    if (currentPage > pagesCount) {
      setCurrentPage(pagesCount);
    }
  };

  let isPageNumberOutOfRange: boolean;

  const pageNumbers = [...new Array(pagesCount)].map((_, index) => {
    const pageNumber = index + 1;
    const isPageNumberFirst = pageNumber === 1;
    const isPageNumberLast = pageNumber === pagesCount;
    const isCurrentPageWithinTwoPageNumbers = Math.abs(pageNumber - currentPage) <= 2;

    if (isPageNumberFirst || isPageNumberLast || isCurrentPageWithinTwoPageNumbers) {
      isPageNumberOutOfRange = false;
      return (
        <Pagination.Item key={pageNumber} onClick={() => onPageNumberClick(pageNumber)} active={pageNumber === currentPage}>
          {pageNumber}
        </Pagination.Item>
      );
    }

    if (!isPageNumberOutOfRange) {
      isPageNumberOutOfRange = true;
      return <Pagination.Ellipsis key={pageNumber} className="muted" />;
    }

    return null;
  });

  useEffect(setLastPageAsCurrent, [itemsCount, currentPage, pagesCount, setCurrentPage]);

  return (
    <Pagination>
      <Pagination.Prev onClick={onPreviousPageClick} disabled={isCurrentPageFirst} />
      {pageNumbers}
      <Pagination.Next onClick={onNextPageClick} disabled={isCurrentPageLast} />
    </Pagination>
  );
};
