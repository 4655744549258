import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

import type { RentmanSettingModuleProps } from '@src/pages/settings/types';

export const InvoiceEmailEnabled: React.FC<RentmanSettingModuleProps> = ({ rentmanUserDefaultData, setRentmanUserDefaultData }) => {
  const handleInvoiceEmailEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRentmanUserDefaultData((prevState) => ({
      ...prevState,
      invoice_email_enabled: event.target.checked,
    }));
  };

  return (
    <Form.Group as={Row} className="mb-3" controlId="formHorizontalInvoiceEmailEnabled">
      <Form.Label column sm={6}>
        Enable recieving emails when Invoice fails
      </Form.Label>
      <Col sm={6}>
        <Form.Check
          type="switch"
          id="connection-switch"
          label="Enable"
          checked={rentmanUserDefaultData?.invoice_email_enabled ? rentmanUserDefaultData?.invoice_email_enabled : false}
          onChange={handleInvoiceEmailEnabled}
        />
      </Col>
    </Form.Group>
  );
};
