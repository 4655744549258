import React from 'react';
//import { Row, Col, Form } from 'react-bootstrap';

import type { DynamicsBcSettingsModuleProps } from '@src/pages/settings/types';

export const DynamicsBcUploadEmail: React.FC<DynamicsBcSettingsModuleProps> = (/*{ dynamicsBcUserDefaultData, setDynamicsBcUserDefaultData }*/) => {
  // const handleEmailUploadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (setDynamicsBcUserDefaultData) {
  //     setDynamicsBcUserDefaultData({
  //       ...dynamicsBcUserDefaultData,
  //       upload_email: event.target.checked,
  //     });
  //   }
  // };

  return (
    <></>
    // <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmailUpload">
    //   <Form.Label column sm={6}>
    //     Enable email for contact attention
    //   </Form.Label>
    //   <Col sm={6}>
    //     <Form.Check
    //       type="switch"
    //       id="email-upload-switch"
    //       label="Enable"
    //       checked={dynamicsBcUserDefaultData.upload_email ? dynamicsBcUserDefaultData.upload_email : false}
    //       onChange={handleEmailUploadChange}
    //     />
    //   </Col>
    // </Form.Group>
  );
};
