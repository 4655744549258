import React, { useEffect, useState } from 'react';
import { Form, Modal, Button, Row, Col, Container } from 'react-bootstrap';
import type { UserProps } from '../dashboard/types';
import { updateUser } from '@src/Api';
import { validateEmail } from '@src/utils/emailUtils';

interface ModalComponentProps {
  showModal: boolean;
  handleModal: () => void;
  selectedUser: UserProps | null;
  refreshUsers: () => void;
}

const EditUserModal: React.FC<ModalComponentProps> = ({ showModal, handleModal, selectedUser, refreshUsers }) => {
  const [newCompanyName, setNewCompanyName] = useState<string>('');
  const [isCompanyNameValid, setIsCompanyNameValid] = useState<boolean>(true);
  const [newEmail, setNewEmail] = useState<string>('');
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [emailInUse, setEmailInUse] = useState<boolean>(true);
  const [newRole, setNewRole] = useState<string>('');
  const [isRoleValid, setIsRoleValid] = useState<boolean>(true);

  useEffect(() => {
    if (selectedUser) {
      setNewCompanyName(selectedUser.users_company_name);
      setNewEmail(selectedUser.users_email);
      setNewRole(selectedUser.users_role);
    }
  }, [selectedUser]);

  const handleUpdateUser = async () => {
    let allFieldsValid = true;

    if (newCompanyName.trim() === '') {
      setIsCompanyNameValid(false);
      allFieldsValid = false;
    }

    if (validateEmail(newEmail) === false) {
      setIsEmailValid(false);
      allFieldsValid = false;
    }

    if (newRole.trim() === '') {
      setIsRoleValid(false);
      allFieldsValid = false;
    }

    if (!allFieldsValid) {
      return;
    }

    try {
      const userId = selectedUser?.users_id;

      if (!userId) {
        return;
      }

      const response = await updateUser(userId, newEmail, newCompanyName, newRole);

      if (response?.status === 200) {
        console.log('User updated successfully!');
        handleModal();
        refreshUsers();
      } else if (response?.status === 409) {
        setIsEmailValid(false);
        setEmailInUse(true);
      }
    } catch (error) {
      console.error('An error occoured while updating the user.');
    }
  };

  return (
    <Modal size="lg" show={showModal} onHide={handleModal} className="modal-data" aria-labelledby="contained-modal-title-vcenter" centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Edit User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleUpdateUser}>
          <Col>
            <Row>
              <Form.Label>You are currently editing user with ID: {selectedUser?.users_id}</Form.Label>
            </Row>
            <Row>
              <Container>
                <Form.Label className="mt-2">Company Name</Form.Label>
                <Form.Control
                  className={`${isCompanyNameValid ? '' : 'input-error'}`}
                  type="text"
                  value={newCompanyName}
                  onChange={(e) => setNewCompanyName(e.target.value)}
                  onFocus={() => setIsCompanyNameValid(true)}
                  onBlur={(e) => (e.target.value.trim() === '' ? setIsCompanyNameValid(false) : setIsCompanyNameValid(true))}
                ></Form.Control>
                <div className="error-container">{!isCompanyNameValid && <p className="error-text">*Company name cannot be empty</p>}</div>
                <Form.Label className="mt-2">Email</Form.Label>
                <Form.Control
                  className={`${isEmailValid ? '' : 'input-error'}`}
                  type="text"
                  value={newEmail}
                  onFocus={() => {
                    setIsEmailValid(true);
                    setEmailInUse(false);
                  }}
                  onChange={(e) => setNewEmail(e.target.value)}
                  onBlur={(e) => setIsEmailValid(validateEmail(e.target.value))}
                ></Form.Control>
                <div className="error-container">
                  {!isEmailValid && <p className="error-text">{emailInUse ? '*The entered Email is already in use.' : '*You have to enter a valid email'}</p>}
                </div>
                <Form.Label className="mt-2">Role</Form.Label>
                <Form.Select className={`${isRoleValid ? '' : 'input-error'}`} as="select" value={newRole} onChange={(e) => setNewRole(e.target.value)}>
                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                </Form.Select>
              </Container>
            </Row>
          </Col>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            handleModal();
            setIsCompanyNameValid(true);
            setIsEmailValid(true);
            setIsRoleValid(true);
          }}
        >
          Close
        </Button>
        <Button variant="primary" type="submit" onClick={handleUpdateUser}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditUserModal;
