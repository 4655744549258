import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';
import { DynamicsBcProductSettings } from './types';


export const CrewProductLedgerCode: React.FC<DynamicsBcProductSettings> = ({ dynamicsBcUserDefaultData, setDynamicsBcUserDefaultData, ledgerCodes }) => {
  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
    const option = event.target.options[event.target.selectedIndex];

    if (setDynamicsBcUserDefaultData) {
      if (option.value.length === 0) {
        delete dynamicsBcUserDefaultData.crew_ledger_code;

        setDynamicsBcUserDefaultData({
          ...dynamicsBcUserDefaultData,
        });
        return;
      }

      setDynamicsBcUserDefaultData({
        ...dynamicsBcUserDefaultData,
        crew_ledger_code: option.value,
      });
    }
  };

  return (
    <Form.Group as={Row} className="mb-3" controlId="formHorizontalProductUnit">
      <Form.Label column sm={6}>
        Specify ledger code for Crew
      </Form.Label>
      <Col sm={6}>
        <FloatingLabel controlId="floatingSelectProductUnit" label="Select product unit">
          <Form.Select aria-label="Floating label select" value={dynamicsBcUserDefaultData.crew_ledger_code} onChange={handleSelectChange}>
            <option value="" data-key={'crewLedgerCode'}>
              Open this select menu
            </option>
            {ledgerCodes &&
              ledgerCodes.length !== 0 &&
              ledgerCodes.map((ledgerCode) => (
                <option key={`crewLedgerCode-${ledgerCode.code}`} value={ledgerCode.id}>
                  {ledgerCode.displayname}
                </option>
              ))}
          </Form.Select>
        </FloatingLabel>
      </Col>
    </Form.Group>
  );
};
