import React from 'react';

import { AdvancedInvoiceLines } from './advanced_invoice_lines';
import type { DynamicsBcSettingsModuleProps } from '@src/pages/settings/types';
import { DynamicsBcUnitOfMeasure } from './unit_of_measure';
import { ledgerCodesByUser } from '@src/Api';
import { LedgerCode } from './types';
import { CrewProductLedgerCode } from './crew_product_ledger_code';
import { CrewProductUnit } from './crew_product_unit';

export const ProductForm: React.FC<DynamicsBcSettingsModuleProps> = ({ dynamicsBcUserDefaultData, setDynamicsBcUserDefaultData }) => {
    const [ledgerCodes, setLedgerCodes] = React.useState<LedgerCode[]>([]);

    React.useEffect(() => {
        const getLedgerCodes = async () => {
            const allLedgerCodes = await ledgerCodesByUser();
            if (allLedgerCodes) {
                setLedgerCodes(allLedgerCodes.data);
            }
        };

        getLedgerCodes();
    }, []);


    const handleFormCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const dataObject = event.target.dataset;

        if (dynamicsBcUserDefaultData) {
            if (setDynamicsBcUserDefaultData) {
                setDynamicsBcUserDefaultData({
                    ...dynamicsBcUserDefaultData,
                    [dataObject.key as string]: event.target.checked,
                });
            }
        }
    };

    return (
        <>
            <AdvancedInvoiceLines dynamicsBcUserDefaultData={dynamicsBcUserDefaultData} handleFormCheckChange={handleFormCheckChange} />
            <DynamicsBcUnitOfMeasure dynamicsBcUserDefaultData={dynamicsBcUserDefaultData} setDynamicsBcUserDefaultData={setDynamicsBcUserDefaultData} />
            <CrewProductLedgerCode dynamicsBcUserDefaultData={dynamicsBcUserDefaultData} setDynamicsBcUserDefaultData={setDynamicsBcUserDefaultData} ledgerCodes={ledgerCodes} />
            {dynamicsBcUserDefaultData.crew_ledger_code && (
                <CrewProductUnit dynamicsBcUserDefaultData={dynamicsBcUserDefaultData} setDynamicsBcUserDefaultData={setDynamicsBcUserDefaultData} />
            )}
        </>
    );
};
