import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Form, Container, Spinner } from 'react-bootstrap';
import { updatePassword, verifyResetToken } from '@src/Api';
import { CustomToast } from '@src/components/toast';
import { usePasswordValidation } from '@src/utils/validation/usePasswordValidation';

export const ResetPassword: React.FC = () => {
  const [token, setToken] = useState<string | null>(null);
  const [tokenIsValid, setTokenIsValid] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState<{ show: boolean; type: 'success' | 'error'; message: string } | null>(null);
  const [isPasswordReset, setIsPasswordReset] = useState<boolean>(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const resetToken = urlParams.get('token');
    setToken(resetToken);

    if (resetToken) {
      verifyToken(resetToken);
    }
  }, []);

  const {
    password,
    confirmPassword,
    passwordError,
    confirmPasswordError,
    mismatchError,
    setPassword,
    setConfirmPassword,
    passwordTouched,
    confirmPasswordTouched,
    setPasswordTouched,
    setConfirmPasswordTouched,
  } = usePasswordValidation('', '');

  function redirectToLogin(url: string) {
    window.location.href = url;
  }

  const handleSetNewPassword = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setLoading(true);

    if (passwordError || confirmPasswordError || mismatchError) {
      setLoading(false);
      return;
    } else if (tokenIsValid && token && password === confirmPassword) {
      try {
        const response = await updatePassword(token, password);
        if (response && response.status === 200) {
          setIsPasswordReset(true);
          setToast({ show: true, type: 'success', message: 'Password successfully changed.' });
        }
      } catch (error) {
        setToast({ show: true, type: 'error', message: 'There was an error updating the password.' });
      } finally {
        setLoading(false);
      }
    }
  };

  const verifyToken = async (token: string) => {
    try {
      const response = await verifyResetToken(token);
      setTokenIsValid(response && response.data.isValid);
    } catch (error) {
      setTokenIsValid(false);
    }
  };

  return (
    <Container className="reset-password-container">
      <Row className="form-signin justify-content-center">
        <Col sm={10}>
          <Form onSubmit={handleSetNewPassword}>
            <Row className="justify-content-center">
              <Col className="text-center">
                <h2 className="mb-4">Reset account password</h2>
                {isPasswordReset && <p>Success!</p>}
              </Col>
            </Row>
            {!isPasswordReset && (
              <Row>
                <Col className="text-center">
                  <p>Enter your new password below</p>
                </Col>
              </Row>
            )}
            <Row>
              {!isPasswordReset && (
                <Form.Group>
                  <Col>
                    <Form.Label>Password*</Form.Label>
                    <Form.Control
                      className={`${(passwordError && passwordTouched) || (confirmPasswordTouched && passwordTouched && mismatchError) ? 'input-error' : ''}`}
                      placeholder="Enter Password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      onFocus={() => setPasswordTouched(false)}
                      onBlur={() => setPasswordTouched(true)}
                    />
                    <div className="error-container mb-2">{passwordError && passwordTouched && <p className="error-text">*{passwordError}</p>}</div>
                  </Col>
                </Form.Group>
              )}
              {!isPasswordReset && (
                <Form.Group className="mb-3">
                  <Col>
                    <Form.Control
                      className={`${
                        (confirmPasswordError && confirmPasswordTouched) || (confirmPasswordTouched && passwordTouched && mismatchError) ? 'input-error' : ''
                      }`}
                      placeholder="Repeat password"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                      onBlur={() => setConfirmPasswordTouched(true)}
                      onFocus={() => setConfirmPasswordTouched(false)}
                    />
                    <div className="error-container mb-1">
                      {confirmPasswordError && confirmPasswordTouched && <p className="error-text">*{confirmPasswordError}</p>}
                    </div>
                  </Col>
                </Form.Group>
              )}
              <Form.Group className="mb-3 text-center">
                <Row>
                  <Col className="error-col">
                    <div className="error-message">{(confirmPasswordTouched && passwordTouched && mismatchError) || ''}</div>
                  </Col>
                </Row>
                {!loading ? (
                  !toast ? (
                    <Button type="submit" className="mt-3 mb-3">
                      Reset Password
                    </Button>
                  ) : (
                    <Button onClick={() => redirectToLogin('/')} className="mt-3 mb-3">
                      Return to login
                    </Button>
                  )
                ) : (
                  <Spinner className="custom-spinner"></Spinner>
                )}
              </Form.Group>
              {!isPasswordReset && (
                <Container className="text-center">
                  <a href="/">Back to login page</a>
                </Container>
              )}
            </Row>
          </Form>
          <Row className="toast-b-right">
            {toast && (
              <CustomToast
                show={toast.show}
                title={toast.type === 'success' ? 'Password Changed' : 'Error!'}
                message={toast.message}
                onClose={() => setToast(null)}
                type={toast.type}
                duration={5000}
              />
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
