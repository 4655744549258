import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import type { DynamicsBcSettingsModuleProps } from '@src/pages/settings/types';
import { SelectedUnitOfMeasure, UnitOfMeasure } from './types';
import { allUnitsOfMeasure } from '@src/Api';

export const DynamicsBcUnitOfMeasure: React.FC<DynamicsBcSettingsModuleProps> = ({
    dynamicsBcUserDefaultData,
    setDynamicsBcUserDefaultData
}) => {
    const [unitsOfMeasure, setUnitsOfMeasure] = React.useState<UnitOfMeasure[]>([]);

    React.useEffect(() => {
        const fetchUnitsOfMeasure = async () => {
            try {
                const unitsOfMeasureResponse = await allUnitsOfMeasure();
                if (unitsOfMeasureResponse && Array.isArray(unitsOfMeasureResponse.data)) {
                    setUnitsOfMeasure(unitsOfMeasureResponse.data);
                } else {
                    setUnitsOfMeasure([]);
                }
            } catch (error) {
                console.error('Failed to fetch units of measure:', error);
                setUnitsOfMeasure([]);
            }
        };

        fetchUnitsOfMeasure();
    }, []);

    const baseUnitOfMeasureChange = (selectedOption: SelectedUnitOfMeasure | null) => {
        if (selectedOption && setDynamicsBcUserDefaultData) {
            setDynamicsBcUserDefaultData({
                ...dynamicsBcUserDefaultData,
                baseUnitOfMeasure: {
                    unitOfMeasureId: selectedOption.unitOfMeasureId,
                    unitOfMeasureCode: selectedOption.unitOfMeasureCode
                }
            });
        }
    };

    const unitsOfMeasureOptions = unitsOfMeasure.map((unitOfMeasure) => ({
        unitOfMeasureId: unitOfMeasure.id,
        unitOfMeasureCode: unitOfMeasure.code,
        label: unitOfMeasure.displayName
    }));

    return (
        <>
            <Form.Group as={Row} className="mb-3" controlId="formHorizontalBaseUnitOfMeasure">
                <Form.Label column sm={6}>
                    Specify default base unit of measure
                </Form.Label>
                <Col sm={6} className="custom-z-index-unit">
                    <Select
                        className='custom-z-index-unit-of-measure'
                        classNamePrefix="react-select"
                        aria-label="Default base unit of measure select"
                        value={unitsOfMeasureOptions.find((option) => option.unitOfMeasureId === dynamicsBcUserDefaultData.baseUnitOfMeasure?.unitOfMeasureId)}
                        onChange={baseUnitOfMeasureChange}
                        options={unitsOfMeasureOptions}
                    />
                </Col>
            </Form.Group>
        </>
    );
};