import React from 'react';
import type { InvoiceTabProps } from './types';
import { resyncInvoice } from '@src/Api';
import { Button, Spinner, Table } from 'react-bootstrap';

export const InvoiceTab: React.FC<InvoiceTabProps> = ({ invoiceData, loading, setToasts, fetchSyncData }) => {
  const [activeInvoiceIds, setActiveInvoiceIds] = React.useState<number[]>([]);

  const handleResyncClick = async (invoiceId: number): Promise<void> => {
    setActiveInvoiceIds((prevActiveInvoiceIds) => [...prevActiveInvoiceIds, invoiceId]);

    const response = await resyncInvoice(invoiceId);

    if (response?.status === 200) {
      const message = response.data.message;
      setToasts((prevToasts) => [...prevToasts, { id: invoiceId, message }]);
      fetchSyncData();
    }

    if (response?.status === 400) {
      const message = response.data.hint;
      setToasts((prevToasts) => [...prevToasts, { id: invoiceId, message }]);
      fetchSyncData();
    }

    setActiveInvoiceIds((prevActiveInvoiceIds) => prevActiveInvoiceIds.filter((id) => id !== invoiceId));
  };

  const getInvoiceStatus = (status: number): string => {
    let statusText: string;

    if (status === 0) {
      statusText = 'Failed';
    } else if (status === 1) {
      statusText = 'Completed';
    } else if (status === 3) {
      statusText = 'Skipped';
    } else {
      statusText = 'Processing';
    }

    return statusText;
  };

  return (
    <Table striped responsive className="enhanced-table">
      <thead>
        <tr>
          <th>Timestamp</th>
          <th>Invoice ID</th>
          <th>Invoice number</th>
          <th>Invoice status</th>
          <th>Action</th>
        </tr>
      </thead>
      {loading ? (
        <tbody>
          <tr>
            <td colSpan={5} className="table-loader">
              <Spinner className="data-loading-spinner"></Spinner>
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {invoiceData.map((invoice) => (
            <tr key={invoice.invoices_id}>
              <td>{new Date(invoice.invoices_created).toLocaleString('da-DK')}</td>
              <td>{invoice.invoices_id}</td>
              <td>{invoice.invoices_number}</td>
              <td>{getInvoiceStatus(invoice.invoices_status)}</td>
              <td>
                {activeInvoiceIds.includes(invoice.invoices_id) ? (
                  <Spinner className="custom-spinner" animation="border" role="status" size="sm">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  <Button onClick={() => handleResyncClick(invoice.invoices_id)}>Re-sync</Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      )}
    </Table>
  );
};
