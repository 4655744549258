import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import type { SelectedShipmentMethod, DynamicsBcShipmentMethod } from './types';
import type { DynamicsBcSettingsModuleProps } from '@src/pages/settings/types';
import { allShipmentMethods } from '@src/Api';

export const DynamicsBcDefaultShipmentMethod: React.FC<DynamicsBcSettingsModuleProps> = ({
    dynamicsBcUserDefaultData,
    setDynamicsBcUserDefaultData
}) => {
    const [shipmentMethods, setShipmentMethods] = React.useState<DynamicsBcShipmentMethod[]>([]);

    React.useEffect(() => {
        const fetchCurrencies = async () => {
            try {
                const shipmentMethodResponse = await allShipmentMethods();
                if (shipmentMethodResponse && Array.isArray(shipmentMethodResponse.data)) {
                    setShipmentMethods(shipmentMethodResponse.data);
                } else {
                    setShipmentMethods([]);
                }
            } catch (error) {
                console.error('Failed to fetch currencies:', error);
                setShipmentMethods([]);
            }
        };

        fetchCurrencies();
    }, []);

    const handleShipmentMethodChange = (selectedOption: SelectedShipmentMethod | null) => {
        if (selectedOption && setDynamicsBcUserDefaultData) {
            setDynamicsBcUserDefaultData({
                ...dynamicsBcUserDefaultData,
                shipmentMethods: {
                    shipmentMethodCode: selectedOption.shipmentMethodCode,
                    shipmentMethodId: selectedOption.shipmentMethodId
                },
            });
        }
    };

    const shipmentMethodOptions = shipmentMethods.map((shipmentMethod) => ({
        shipmentMethodId: shipmentMethod.id,
        shipmentMethodCode: shipmentMethod.code,
        label: shipmentMethod.displayName
    }));

    return (
        <Form.Group as={Row} className="mb-3" controlId="formHorizontalShipmentMethod">
            <Form.Label column sm={6}>
                Specify default shipment method for invoices
            </Form.Label>
            <Col sm={6} className="custom-z-index-shipment">
                <Select
                    classNamePrefix="react-select"
                    aria-label="Floating label select"
                    value={shipmentMethodOptions.find((option) => option.shipmentMethodId === dynamicsBcUserDefaultData.shipmentMethods?.shipmentMethodId)}
                    onChange={handleShipmentMethodChange}
                    options={shipmentMethodOptions}
                />
            </Col>
        </Form.Group>
    );
};
